// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-articles-js": () => import("./../src/components/articles.js" /* webpackChunkName: "component---src-components-articles-js" */),
  "component---src-components-case-study-js": () => import("./../src/components/case-study.js" /* webpackChunkName: "component---src-components-case-study-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-business-web-application-development-js": () => import("./../src/pages/business-web-application-development.js" /* webpackChunkName: "component---src-pages-business-web-application-development-js" */),
  "component---src-pages-case-studies-js": () => import("./../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cookies-js": () => import("./../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-custom-web-development-process-js": () => import("./../src/pages/custom-web-development-process.js" /* webpackChunkName: "component---src-pages-custom-web-development-process-js" */),
  "component---src-pages-custom-web-development-stack-js": () => import("./../src/pages/custom-web-development-stack.js" /* webpackChunkName: "component---src-pages-custom-web-development-stack-js" */),
  "component---src-pages-digital-transformation-js": () => import("./../src/pages/digital-transformation.js" /* webpackChunkName: "component---src-pages-digital-transformation-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-form-js": () => import("./../src/pages/landing-form.js" /* webpackChunkName: "component---src-pages-landing-form-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-web-development-careers-js": () => import("./../src/pages/web-development-careers.js" /* webpackChunkName: "component---src-pages-web-development-careers-js" */),
  "component---src-pages-web-development-for-non-profits-js": () => import("./../src/pages/web-development-for-non-profits.js" /* webpackChunkName: "component---src-pages-web-development-for-non-profits-js" */),
  "component---src-pages-web-development-quote-js": () => import("./../src/pages/web-development-quote.js" /* webpackChunkName: "component---src-pages-web-development-quote-js" */)
}

